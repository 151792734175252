import { Component, Input, OnInit } from "@angular/core";
import { CoreService } from "../../../shared/services/core.service";
import { AuthService } from "../../../shared/services/auth.service";
import { CookieService } from "ngx-cookie-service";
import { NotificationService } from "../../../shared/services/notification.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-main-header",
  templateUrl: "./main-header.component.html",
  styleUrls: ["./main-header.component.scss"],
})
export class MainHeaderComponent implements OnInit {
  @Input() auth = false;
  isCollapsed = true;
  user;
  startvalue = 0;
  limitvalue = this.coreService.pageSize;
  is_last = 1;
  notificationList = [];
  count: void;

  constructor(
    private coreService: CoreService,
    private authService: AuthService,
    private cookieService: CookieService,
    private notificationService: NotificationService,
    private router: Router
  ) {
    if (this.auth) {
      // this.getUser();
      // this.getHeader();
      // // this.list();
      // this.notificationService.bellIconList();
    }
  }

  ngOnInit() {
    this.getUser();
      this.getHeader();
      this.list();
      // this.notificationService.bellIconList();
      
    this.coreService.notificationList.subscribe((response) => {
      // console.log('ppppap',response);
      // this.count = response?.count;
    });
  }

  getUser() {
    this.authService.getProfile().subscribe((response) => {
      this.coreService.currentUserSubject.next(response.data.user);
    });
  }

  getHeader() {
    
    this.coreService.currentUser.subscribe((response) => {
      this.user = response;
      
    });
  }

  logOut() {
    this.authService.logout().subscribe((response) => {
      if (response.success == 1) {
        this.coreService.currentUserSubject.next(null);
        this.cookieService.deleteAll("/");
        localStorage.clear();
        this.router.navigate(["/"]);
      }
    });
  }

  list() {
    // let data = {
    //   start: this.startvalue,
    //   limit: this.limitvalue,
    // };
    // this.notificationService.list(data).subscribe((response) => {
    //   if (response.success == 1) {
    //     this.notificationList = response.data.notifications;
    //   }
    // });

    this.notificationService.unreadCount().subscribe((response) => {
      if (response.success == 1) {
        let data = response.data;
        this.notificationList = data.list;
        this.count = data.notification_count;
      }
    });
  }

  readNotification(id) {
    let data = { notification_id: id, is_readAll: 0 };
    this.notificationService.read(data).subscribe((response) => {
      if (response.success == 1) {
        this.notificationService.bellIconList();
      }
    });
  }
}
