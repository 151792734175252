import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { CoreService } from "src/app/shared/services/core.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  submitted = false;
  modalRef: BsModalRef;

  modalTitle;
  modalMessage;
  modalButtonText;

  email = new FormControl(null, [
    Validators.required,
    Validators.email
  ]);
  actor = new FormControl(5, []);
  form = new UntypedFormGroup({
    email: this.email,
    actor:this.actor
  });

  constructor(
    private modalService: BsModalService,
    private coreService: CoreService,
    private authService: AuthService,
    private router: Router
  ) { }

  get f() {
    return this.form.controls;
  }

  ngOnInit() {
  }

  onSubmit(template) {
    this.submitted = true;
    if (this.form.valid) {
      let data = {
        ...this.form.value
      }
      this.authService.forgotPassword(data).subscribe(response => {
        if (response.success == 1) {
          this.modalTitle = 'Forgot Password';
          this.modalButtonText = 'ok';
          this.modalMessage = response.data.message;
          this.openModal(template);
          this.form.reset();
        }
      })
    }
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, {
        class: 'modal-sm modal-dialog-centered', keyboard: false, backdrop: true,
        ignoreBackdropClick: true
      })
    );
  }

  onButtonClick() {
    this.modalRef.hide();
    this.router.navigate(['/auth/sign-in']);
  }

}
