import { FormControl, FormGroup } from "@angular/forms";

export function MustMatch(controlName: string) {
  let control: FormControl;
  let confirmControl: FormControl;

  return (formControl: FormControl) => {
    if (!formControl.parent) {
      return null;
    }
    if (!confirmControl) {
      confirmControl = formControl;
      control = formControl.parent.get(controlName) as FormControl;
      control.valueChanges.subscribe(() => {
        confirmControl.updateValueAndValidity();
      });
    }

    if (control.value !== confirmControl.value) {
      return {
        notMatch: true,
      };
    }
    return null;
  };
}
