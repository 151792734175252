<div class="modal-content">
    <div class="modal-header py-3">
        <h3 class="modal-title text-capitalize mb-0" id="ApplyPromocodeTitle">Apply Promocode</h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModel()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="input-group mb-3">
            <input type="text" class="form-control form-control-lg" placeholder="Enter Promocode" [formControl]="promocode">
            <div class="input-group-append">
                <button class="btn btn-primary-theme btn-lg" type="button" (click)="checkPromocode()">Apply</button>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table remove-left-right-padding mb-0 align-middle text-left">
                <tbody>
                    <tr *ngFor="let item of promocodeList">
                        <td>
                            <p class="font-size-16 text-muted mb-2">{{ item?.valid_till * 1000 | date: 'dd/MM/yyyy' }}</p>
                            {{ item?.promocode }}
                        </td>
                        <td class="text-right">
                            <button type="button" class="btn btn-primary-theme-outline" (click)="applyPromocode(item)">Apply</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <!-- <div class="modal-footer py-3">
        <button type="button" class="btn btn-primary-theme btn-lg btn-block" data-dismiss="modal">Cancel</button>
    </div> -->
</div>