import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Router } from "@angular/router";
import { throwError, Observable } from "rxjs";
// import "rxjs/add/operator/map";
// import "rxjs/add/operator/catch";
import { CoreService } from "./core.service";

import { catchError } from "rxjs/operators";
import { CookieService } from "ngx-cookie-service";
@Injectable({
  providedIn: "root",
})
export class ApiService {
  API_URL: String = environment.API_URL;
  avoidCompanyIdArray = [];

  constructor(
    private http: HttpClient,
    private router: Router,
    private cookieService: CookieService,
    public coreService: CoreService
  ) {}

  private setHeaders(): HttpHeaders {
    const headersConfig = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    if (this.cookieService.check("accessToken"))
      headersConfig["Authorization"] = this.cookieService.get("accessToken");
    return new HttpHeaders(headersConfig);
  }

  private setFormDataHeaders(): HttpHeaders {
    const headersConfig = {};

    if (this.cookieService.check("accessToken")) {
      headersConfig["Authorization"] = this.cookieService.get("accessToken");
    }
    return new HttpHeaders(headersConfig);
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${this.API_URL}${path}`, {
      headers: this.setHeaders(),
      params: params,
    });
    // .catch((error: any) => {
    //   if (error.status === 401) {
    //     this.coreService.purgeAuth();
    //     this.router.navigateByUrl("/auth/login");
    //   }
    //   return throwError(error);
    // });
  }

  put(path: string, body): Observable<any> {
    return this.http.put(`${this.API_URL}${path}`, body, {
      headers: this.setHeaders(),
    });
    // .catch((error: any) => {
    //   if (error.status === 401) {
    //     this.coreService.purgeAuth();
    //     this.router.navigateByUrl("/auth/login");
    //   }
    //   return throwError(error.json());
    // });
  }

  formDataPut(path: string, body): Observable<any> {
    return this.http.post(`${this.API_URL}${path}`, body, {
      headers: this.setFormDataHeaders(),
    });
    // .catch((error: any) => {
    //   if (error.status === 401) {
    //     this.coreService.purgeAuth();
    //     this.router.navigateByUrl("/auth/login");
    //   }
    //   return throwError(error);
    // });
  }

  post(path: string, body): Observable<any> {
    return this.http.post(`${this.API_URL}${path}`, body, {
      headers: this.setHeaders(),
    });
    // .catch((error: any) => {
    //   if (error.status === 401) {
    //     this.coreService.purgeAuth();
    //     this.router.navigateByUrl("/auth/login");
    //   }
    //   return throwError(error);
    // });
  }

  formDataPost(path: string, body): Observable<any> {
    return this.http.post(`${this.API_URL}${path}`, body, {
      headers: this.setFormDataHeaders(),
    });
    // .catch((error: any) => {
    //   if (error.status === 401) {
    //     this.coreService.purgeAuth();
    //     this.router.navigateByUrl("/auth/login");
    //   }
    //   return throwError(error);
    // });
  }

  patch(path: string, body): Observable<any> {
    return this.http.patch(`${this.API_URL}${path}`, body, {
      headers: this.setHeaders(),
    });
    // .catch((error: any) => {
    //   if (error.status === 401) {
    //     this.coreService.purgeAuth();
    //     this.router.navigateByUrl("/auth/login");
    //   }
    //   return throwError(error);
    // });
  }

  formDataPatch(path: string, body): Observable<any> {
    return this.http.patch(`${this.API_URL}${path}`, body, {
      headers: this.setFormDataHeaders(),
    });
    // .catch((error: any) => {
    //   if (error.status === 401) {
    //     this.coreService.purgeAuth();
    //     this.router.navigateByUrl("/auth/login");
    //   }
    //   return throwError(error);
    // });
  }

  delete(path: string): Observable<any> {
    return this.http.delete(`${this.API_URL}${path}`, {
      headers: this.setHeaders(),
    });
    // .catch((error: any) => {
    //   if (error.status === 401) {
    //     this.coreService.purgeAuth();
    //     this.router.navigateByUrl("/auth/login");
    //   }
    //   return throwError(error);
    // });
  }

  getFile(
    path: string,
    params: HttpParams = new HttpParams()
  ): Observable<any> {
    return this.http.get(`${this.API_URL}${path}`, {
      headers: this.setHeaders(),
      params: params,
      responseType: "blob",
    });
    // .catch((error: any) => {
    //   if (error.status === 401) {
    //     this.coreService.purgeAuth();
    //     this.router.navigateByUrl("/auth/login");
    //   }
    //   return throwError(error);
    // });
  }
}
