export enum cms_type {
  Customers = 1,
  Drivers = 2,
}

export enum OrderGeneralStatus {
  OnGoing = 1,
  Completed = 2,
  Cancelled = 3,
}

export enum Actors {
  Admin = 1,
  Subadmin = 2,
  Vendor = 3,
  User = 5
}

export enum discountType {
  Percentage = 1,
  Price = 2,
  Rate = 3
}

export enum OrderType {
  PickUp = 1,
  Delivery = 2,
}

export enum depotType {
  Inland = 1,
  Coastal = 2,
}

export enum tripPointValue {
  onePoint = 100,
}

export enum PendingOrderStatus {
  AwaitingPaymentConfirmation = 1,
  PaymentConfirmed = 2,
  TruckNotimation = 3,
  Released = 4,
  Completed = 5,
  Cancelled = 6,
  Refunded = 7,
}

export enum DeliveryOrderStatus {
  AwaitingPaymentConfirmation = 1,
  PaymentConfirmed = 2,
  OrderAssigned = 3,
  OutForDelivery = 4,
  ArrivedDestination = 5,
  CompleteDelivery = 6,
  Cancelled = 7,
  Refunded = 8,
}

export enum PaymentType {
  OnlinePayment = 1,
  BankTransfer = 2,
  Credit = 3,
}

export enum slipType {
  Debit_Alert = 1,
  Confirmation_slip = 2
}