import { Component, OnInit } from "@angular/core";
import { CoreService } from "src/app/shared/services/core.service";

@Component({
  selector: "app-main-template",
  templateUrl: "./main-template.component.html",
  styleUrls: ["./main-template.component.scss"],
})
export class MainTemplateComponent implements OnInit {
  isAuthenticated = false;
  user:any;
  constructor(private coreService: CoreService) {
    this.coreService.currentUser.subscribe((resp)=>{
      this.user = resp
    })

    this.coreService.isAuthenticated.subscribe((resp) => {
      this.isAuthenticated = resp;
    });
  }

  ngOnInit() {}
}
