import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-message-box",
  templateUrl: "./message-box.component.html",
  styleUrls: ["./message-box.component.scss"],
})
export class MessageBoxComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;
  @Input() buttonText: string;
  @Output() buttonClicked = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onButtonClick() {
    this.buttonClicked.emit();
  }
}
