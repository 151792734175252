import { Component, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-otp-box",
  templateUrl: "./otp-box.component.html",
  styleUrls: ["./otp-box.component.scss"],
})
export class OtpBoxComponent implements OnInit {
  @Output() buttonClicked = new EventEmitter();
  constructor() {}

  ngOnInit() {}

  onButtonClick() {
    this.buttonClicked.emit();
  }
}
