<div class="modal-content">
  <div class="modal-body text-center">
    <h3 class="modal-title mb-3">
      {{ title }}
    </h3>
    <p class="font-size-18 text-muted mb-4">
      {{ message }}
    </p>
    <button
      type="button"
      class="btn btn-primary-theme btn-lg btn-block"
      data-dismiss="modal"
      (click)="onButton1Click()"
    >
      {{ button1Text }}
    </button>
    <button
      type="button"
      class="btn btn-primary-theme btn-lg btn-block"
      data-dismiss="modal"
      (click)="onButton2Click()"
    >
      {{ button2Text }}
    </button>
  </div>
</div>
