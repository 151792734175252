import { Component, OnInit } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { AuthService } from "../../../shared/services/auth.service";

@Component({
  selector: "app-sub-template",
  templateUrl: "./sub-template.component.html",
  styleUrls: ["./sub-template.component.scss"],
})
export class SubTemplateComponent implements OnInit {
  notification_token;

  constructor(
    private cookieService: CookieService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    // this.notification_token = this.cookieService.get("notification_token");
    // this.getToken();
  }

  // getToken() {
  //   let data = {
  //     device_id: this.notification_token,
  //     device_type: "A",
  //   };
  //   this.authService.getToken(data).subscribe((response) => {
  //     if (response.success == 1) {
  //       this.cookieService.set(
  //         "accessToken",
  //         response.data.auth.type + " " + response.data.auth.token,
  //         1,
  //         "/",
  //         "",
  //         false,
  //         "Lax"
  //       );
  //     }
  //   });
  // }
}
