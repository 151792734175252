import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-delete-box",
  templateUrl: "./delete-box.component.html",
  styleUrls: ["./delete-box.component.scss"],
})
export class DeleteBoxComponent implements OnInit {
  @Input() id;
  @Input() title: string;
  @Input() message: string;
  @Input() button1Text: string;
  @Input() button2Text: string;
  @Output() button1Clicked = new EventEmitter();
  @Output() button2Clicked = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  onButton1Click() {
    this.button1Clicked.emit();
  }

  onButton2Click() {
    this.button2Clicked.emit(this.id);
  }
}
