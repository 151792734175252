<div class="modal-content">
    <div class="modal-body text-center">
        <h3 class="modal-title text-capitalize mb-3" id="AddBankInfoTitle">Add Bank Info</h3>
        <p class="font-size-18 mb-3">Your cancellation under process. please add bank account details for refund amount.</p>
        <div class="mb-4">
            <span class="badge badge-danger text-wrap text-left lh-normal">Note: No charges are applied & amount will be refunded into your bank account.</span>
        </div>
        <div class="form-row">
            <div class="col">
                <button type="button" class="btn btn-secondary-theme-outline btn-lg btn-block" data-dismiss="modal">View Home</button>
            </div>
            <div class="col">
                <button type="button" class="btn btn-primary-theme btn-lg btn-block" data-dismiss="modal">Add Bank</button>
            </div>
        </div>
    </div>
</div>