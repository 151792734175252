import { Pipe, PipeTransform } from "@angular/core";
import { DeliveryOrderStatus } from "../constants";

@Pipe({
    name: "deliveryStatusColor",
})
export class DeliveryStatusColorPipe implements PipeTransform {
    transform(value: any): any {
        switch (value) {
            case DeliveryOrderStatus.AwaitingPaymentConfirmation:
                return "badge badge-danger";
                // return "label-info";
                break;
            case DeliveryOrderStatus.PaymentConfirmed:
                return "badge badge-info";
                // return "label-info";
                break;
            case DeliveryOrderStatus.OrderAssigned:
                return "label-blue";
                // return "label-info";
                break;
            case DeliveryOrderStatus.OutForDelivery:
                return "badge badge-success";
                // return "label-info";
                break;
            case DeliveryOrderStatus.ArrivedDestination:
                return "badge badge-info";
                // return "label-info";
                break;
            case DeliveryOrderStatus.Cancelled:
                return "badge badge-muted";
                // return "label-info";
                break;
            case DeliveryOrderStatus.Refunded:
                return "badge badge-success";
                // return "label-info";
                break;
            case DeliveryOrderStatus.CompleteDelivery:
                return "badge badge-success";
                // return "label-info";
                break;
        }
        return null;
    }
}
