import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CoreService } from '../services/core.service';
import { CookieService } from "ngx-cookie-service";
import { Actors } from '../constants';

@Injectable({ providedIn: 'root' })
export class LoginAuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private coreService: CoreService,
        private cookieService: CookieService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let currentuser;
        let currentUser = this.coreService.currentUser.subscribe(users => currentuser = users);
        currentUser.unsubscribe();
        const token = this.cookieService.check("accessToken");
        const user = this.cookieService.check("user");
        if (token && user) {
            const current_user = JSON.parse(this.cookieService.get('user'))
            if (current_user.user_type == Actors.User) {
                this.router.navigate(['/order/list']);
                return false;
            }
        }
        return true
    }
}