<div class="content">
  <div class="section section-padding">
    <div class="container">
      <div class="auth-box">
        <div class="section-title text-center mb-4 mb-md-5">
          <h1 class="heading mb-3 h3"> Customer Log In</h1>
          <p class="text-muted font-size-18">Sign In to your account</p>
        </div>
        <form [formGroup]="form" (submit)="onSubmit()">
          <div class="form-label-group">
            <input type="email" class="form-control form-control-lg" placeholder="Email" autofocus [formControl]="email" />
            <label>Email</label>
            <div *ngIf="f.email.errors" class="invalid-feedback custom-validation">
              <p *ngIf="submitted && f.email.errors.required" class="help text-danger">
                Please enter Email Id.
              </p>
              <p *ngIf="f.email.errors.email" class="help text-danger">
                Please enter valid email.
              </p>
            </div>
          </div>
          <div class="form-label-group has-password-icon">
            <input [type]="password_type" class="form-control form-control-lg" placeholder="Password" id="password" autocomplete="off"
              data-toggle="password" [formControl]="password" />
            <label>Password</label>
            <span class="form-control-feedback zmdi-hc-lg toggle-password" [ngClass]="is_show_password ? 'zmdi zmdi-eye' : 'zmdi zmdi-eye-off'" toggle="#password" (click)="onclisckShowPassword()"></span>
            <div *ngIf="f.password.errors" class="invalid-feedback custom-validation">
              <p *ngIf="submitted && f.password.errors.required" class="help text-danger">
                Please enter Password.
              </p>
              <p *ngIf="f.password.errors.minlength" class="help text-danger">
                Password must be 6 characters long.
              </p>
              <p *ngIf="f.password.errors.maxlength" class="help text-danger">
                Password contain maximum 20 Characters.
              </p>
            </div>
          </div>
          <div class="text-center mt-3">
            <a routerLink="/auth/forgot-password" class="text-secondary">Forgot Password?</a>
          </div>
          <div class="form-action text-right mt-4">
            <!-- <button
              class="btn btn-primary-theme btn-lg btn-block"
              (click)="openModal(template)"
            >
              Sign In
            </button> -->
            <input type="submit" class="btn btn-primary-theme btn-lg btn-block" value="Sign In" />
            <!-- routerLink="/new-order" -->
          </div>
          <div class="text-center mt-4">
            <a routerLink="/auth/sign-up" [queryParams]="token ? token : ''" class="text-primary text-uppercase font-size-18 font-weight-medium">Create an Account</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<ng-template #template>
  <app-confirm-box [title]="modalTitle" [message]="modalMessage" [button1Text]="modalButton1Text" [button2Text]="modalButton2Text"
    (button1Clicked)="onButton1Click()" (button2Clicked)="onButton2Click()"></app-confirm-box>
</ng-template>