<!-- Add After Login header and footer here -->
<!-- <div id="app">
  <app-sidebar class="sidebar sidebar-left"></app-sidebar>
  <div class="content-wrapper d-flex flex-column">
    <app-header [user]="currentUser$ | async"></app-header>
    <router-outlet></router-outlet>
  </div>
</div> -->

<ng-container>
  <app-main-header *ngIf="isAuthenticated && user"></app-main-header>
  <app-sub-header *ngIf="!isAuthenticated || !user"></app-sub-header>
</ng-container>
<router-outlet></router-outlet>
<app-footer></app-footer>
