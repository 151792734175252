import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  FormatPhoneNumberDirective
} from './directives/format-phone-number.directive'
import { MessageBoxComponent } from "./components/message-box/message-box.component";
import { OtpBoxComponent } from "./components/otp-box/otp-box.component";
import { ConfirmBoxComponent } from "./components/confirm-box/confirm-box.component";
import { DeleteBoxComponent } from "./components/delete-box/delete-box.component";

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { OrderStatusPipe } from './pipe/order-status.pipe';
import { StatusColorPipe } from './pipe/status-color.pipe';
import { DeliveryOrderStatusPipe } from './pipe/delivery-order-status.pipe';
import { DeliveryStatusColorPipe } from './pipe/delivery-status-color.pipe';
import { CancelBoxComponent } from './components/cancel-box/cancel-box.component';
import { AddBankInfoBoxComponent } from './components/add-bank-info-box/add-bank-info-box.component';
import { ApplyPromocodeBoxComponent } from './components/apply-promocode-box/apply-promocode-box.component';
import { AddToLoadComponent } from './components/add-to-load/add-to-load.component';

@NgModule({
  declarations: [MessageBoxComponent, OrderStatusPipe, StatusColorPipe, DeliveryStatusColorPipe, DeliveryOrderStatusPipe, FormatPhoneNumberDirective, DeleteBoxComponent, OtpBoxComponent, ConfirmBoxComponent, CancelBoxComponent, AddBankInfoBoxComponent, ApplyPromocodeBoxComponent, AddToLoadComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  exports: [MessageBoxComponent, AddToLoadComponent, CancelBoxComponent, AddBankInfoBoxComponent, ApplyPromocodeBoxComponent, OrderStatusPipe, StatusColorPipe, DeliveryOrderStatusPipe, DeliveryStatusColorPipe, FormatPhoneNumberDirective, DeleteBoxComponent, OtpBoxComponent, ConfirmBoxComponent],
})
export class SharedModule { }
