<div class="content">
  <div class="section section-padding">
    <div class="container">
      <div class="auth-box">
        <div class="section-title text-center mb-4 mb-md-5">
          <h1 class="heading mb-3 h3">Complete Business Profile</h1>
          <p class="text-muted font-size-18">
            Please fill up this form to Complete your Profile!
          </p>
        </div>
        <form [formGroup]="form" (submit)="onSubmit(template)">
          <div class="form-label-group">
            <input type="text" class="form-control form-control-lg" placeholder="Position/Title" autofocus [formControl]="position" />
            <label>Position/Title</label>
            <div *ngIf="submitted && f.position.errors" class="invalid-feedback custom-validation">
              <p *ngIf="f.position.errors.required" class="help text-danger">
                Please enter Position/Title.
              </p>
            </div>
          </div>
          <div class="form-label-group street-name">
            <input ngx-google-places-autocomplete type="text" class="form-control" placeholder="Street Name & Number" (onAddressChange)="handleAddressChange($event)"
              [formControl]="business_location" />
            <!-- <input type="text" class="form-control form-control-lg" placeholder="Business Address" [formControl]="business_location"
            /> -->
            <label>Street Name & Number</label>
            <div *ngIf="submitted && f.business_location.errors" class="invalid-feedback custom-validation">
              <p *ngIf="f.business_location.errors.required" class="help text-danger">
                Please enter Street Name & Number.
              </p>
            </div>
          </div>
          <!-- <div class="form-label-group">
            <input type="text" class="form-control form-control-lg" placeholder="Street Name & Number" />
            <label>Street Name & Number</label>
            <div *ngIf="submitted && f.business_location.errors" class="invalid-feedback custom-validation">
              <p *ngIf="f.business_location.errors.required" class="help text-danger">
                Please enter Street Name & Number.
              </p>
            </div>
          </div> -->
          <div class="form-label-group">
            <input type="text" class="form-control form-control-lg" placeholder="City" [formControl]="city" />
            <label>City</label>
            <div *ngIf="submitted && f.city.errors" class="invalid-feedback custom-validation">
              <p *ngIf="f.city.errors.required" class="help text-danger">
                Please enter city.
              </p>
            </div>
          </div>
          <div class="form-label-group select2-lg">
            <ng-select [items]="states" bindLabel="name" bindValue="id" placeholder="Select State" [formControl]="state_id" (change)="getLocalGovt(form.value.state_id)">
            </ng-select>
            <div *ngIf="submitted && f.state_id.errors" class="invalid-feedback custom-validation">
              <p *ngIf="f.state_id.errors.required" class="help text-danger">
                Please select state.
              </p>
            </div>

          </div>
          <div class="form-label-group select2-lg">
            <ng-select [items]="localGovt" bindLabel="name" bindValue="id" placeholder="Local Government" [formControl]="local_govt_id">
            </ng-select>
            <div *ngIf="submitted && f.local_govt_id.errors" class="invalid-feedback custom-validation">
              <p *ngIf="f.local_govt_id.errors.required" class="help text-danger">
                Please select local govt.
              </p>
            </div>
          </div>
          <div>
          <div class="form-label-group">
            <div>
              <label style="margin-top: 6px" class="control-label">Filling Plant License</label>
              <div class="">
                <div class="" >
                  <div class="">
                    <div class="">
                      <div class="fileinput fileinput-new0 fileinput-exists m-0" [class.fileinput-new]="!urls"
                        [class.fileinput-exists]="urls" data-proivdes="fileinput" style="width: 100%">
                        <div class="input-group input-large">
                          <div class="form-control" data-trigger="fileinput">
                            <!-- <i class="fa fa-file fileinput-exists0"></i>&nbsp; -->
                            <i class="zmdi zmdi-file"></i>
                            <span class="fileinput-filename">
                              {{reviewImage}}
                            </span>
                          </div>
                          <span class="input-group-addon btn btn-primary-theme-outline btn-file login-fileinput fileinput-new h-35">
                            <span class="fileinput-new">
                              {{ 'Upload' }}
                            </span>
                            <input type="file" name="..." (change)="changeFile($event,1)" #myFileInput />
                          </span>
                      
                          <button class="input-group-addon btn btn-secondary-theme-outline login-fileinput fileinput-exists h-35" data-dismiss="fileinput"
                            type="button" (click)="removeImage(1)">
                            {{ 'Remove' }}
                          </button>
                          <ng-container *ngIf="urls?.match(urlRegPdf) || reviewImage?.match(urlRegPdf)">
                            <a class="ml-3 align-self-center" (click)="downloadDocument(urls)"><i  class='zmdi zmdi-eye'
                                ></i></a>
                          </ng-container>
                        </div>
                        <div *ngIf="urls?.match(urlRegImage) || reviewImage?.match(urlRegImage)"
                           class="fileinput-preview fileinput-exists mt-2"
                          data-trigger="fileinput">
                          <img [src]="urls ? urls : null" (click)="downloadDocument(urls)" style=" border-radius: 5px; height: 100px; width: 100px;">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-label-group">
            <div>
              <label style="margin-top: 6px" class="control-label ">Registration Documents</label>
              <div class="">
                <div class="" >
                  <div class="">
                    <div class="">
                      <div class="fileinput fileinput-new0 fileinput-exists m-0" [class.fileinput-new]="!registrationUrl"
                        [class.fileinput-exists]="registrationUrl" data-proivdes="fileinput" style="width: 100%">
                        <div class="input-group input-large">
                          <div class="form-control" data-trigger="fileinput">
                            <!-- <i class="fa fa-file fileinput-exists0"></i>&nbsp; -->
                            <i class="zmdi zmdi-file"></i>
                            <span class="fileinput-filename">
                              {{registrationImage}}
                            </span>
                          </div>
                          <span class="input-group-addon btn btn-primary-theme-outline btn-file login-fileinput fileinput-new h-35">
                            <span class="fileinput-new">
                              {{ 'Upload' }}
                            </span>
                            <input type="file" name="..." (change)="changeFile($event,2)" #myFileInput />
                          </span>
                      
                          <button class="input-group-addon btn btn-secondary-theme-outline login-fileinput fileinput-exists h-35" data-dismiss="fileinput"
                            type="button" (click)="removeImage(2)">
                            {{ 'Remove' }}
                          </button>
                          <ng-container *ngIf="registrationUrl?.match(urlRegPdf) || registrationImage?.match(urlRegPdf)">
                            <a class="ml-3 align-self-center" (click)="downloadDocument(registrationUrl)"><i class='zmdi zmdi-eye'
                                ></i></a>
                          </ng-container>
                        </div>
                        <div *ngIf="registrationUrl?.match(urlRegImage) || registrationImage?.match(urlRegImage)"
                           class="fileinput-preview fileinput-exists mt-2"
                          data-trigger="fileinput">
                          <img [src]="registrationUrl ? registrationUrl : null" (click)="downloadDocument(registrationUrl)"  style=" border-radius: 5px; height: 100px; width: 100px;">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-label-group">
            <div>
              <label style="margin-top: 6px" class="control-label">Insurance Certificate</label>
              <div class="">
                <div class="" >
                  <div class="">
                    <div class="">
                      <div class="fileinput fileinput-new0 fileinput-exists m-0" [class.fileinput-new]="!insuranceUrl"
                        [class.fileinput-exists]="insuranceUrl" data-proivdes="fileinput" style="width: 100%">
                        <div class="input-group input-large">
                          <div class="form-control" data-trigger="fileinput">
                            <!-- <i class="fa fa-file fileinput-exists0"></i>&nbsp; -->
                            <i class="zmdi zmdi-file"></i>
                            <span class="fileinput-filename">
                              {{insuranceImage}}
                            </span>
                          </div>
                          <span class="input-group-addon btn btn-primary-theme-outline btn-file login-fileinput fileinput-new h-35">
                            <span class="fileinput-new">
                              {{ 'Upload' }}
                            </span>
                            <input type="file" name="..." (change)="changeFile($event,3)" #myFileInput />
                          </span>
                      
                          <button class="input-group-addon btn btn-secondary-theme-outline login-fileinput fileinput-exists h-35" data-dismiss="fileinput"
                            type="button" (click)="removeImage(3)">
                            {{ 'Remove' }}
                          </button>
                          <ng-container *ngIf="insuranceUrl?.match(urlRegPdf) || insuranceImage?.match(urlRegPdf)">
                            <a class="ml-3 align-self-center" (click)="downloadDocument(insuranceUrl)"><i class='zmdi zmdi-eye'
                                ></i></a>
                          </ng-container>
                        </div>
                        <div *ngIf="insuranceUrl?.match(urlRegImage) || insuranceImage?.match(urlRegImage)"
                           class="fileinput-preview fileinput-exists mt-2"
                          data-trigger="fileinput">
                          <img [src]="insuranceUrl ? insuranceUrl : null" (click)="downloadDocument(insuranceUrl)"  style=" border-radius: 5px; height: 100px; width: 100px;">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>          
          <div class="form-action text-right mt-4">
            <input type="button" class="btn btn-primary-theme btn-lg btn-block" value="Submit" (click)="onSubmit(template)" [disabled]="!form.valid"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</div>



<!-- Thank You Account Created Modal -->
<ng-template #template>
  <app-message-box [title]="modalTitle" [message]="modalMessage" [buttonText]="modalButtonText" (buttonClicked)="onButtonClick()"></app-message-box>
</ng-template>