<div class="content">
  <div class="section section-padding">
    <div class="container">
      <div class="auth-box">
        <div class="section-title text-center mb-4 mb-md-5">
          <h1 class="heading mb-3 h3">CUSTOMER Sign Up</h1>
          <p class="text-muted font-size-18">
            Please fill up this form to create an account!
          </p>
        </div>
        <form [formGroup]="form" (submit)="onSubmit(template)">
          <div class="form-label-group">
            <input type="text" class="form-control form-control-lg" placeholder="Business Name" autofocus [formControl]="business_name"
            />
            <label>Business Name</label>
            <div *ngIf="submitted && f.business_name.errors" class="invalid-feedback custom-validation">
              <p *ngIf="f.business_name.errors.required" class="help text-danger">
                Please enter Business Name.
              </p>
            </div>
          </div>
          <div class="form-label-group">
            <input type="text" class="form-control form-control-lg" placeholder="Contact Person Name" [formControl]="name" />
            <label>Contact Person Name</label>
            <div *ngIf="submitted && f.name.errors" class="invalid-feedback custom-validation">
              <p *ngIf="f.name.errors.required" class="help text-danger">
                Please enter Contact Person Name.
              </p>
            </div>
          </div>
          <div class="form-label-group">
            <input type="email" class="form-control form-control-lg" placeholder="Email" [formControl]="email" />
            <label>Email</label>
            <div *ngIf="f.email.errors" class="invalid-feedback custom-validation">
              <p *ngIf="submitted && f.email.errors.required" class="help text-danger">
                Please enter Email Id.
              </p>
              <p *ngIf="f.email.errors.email" class="help text-danger">
                Please enter valid email.
              </p>
            </div>
          </div>
          <div class="form-label-group">
            <div class="form-label-group has-country-code has-not-verified">
              <span class="form-control-feedback country-code">+234
              </span>
              <input type="text" class="form-control form-control-lg pt-1" placeholder="Mobile Number" [formControl]="mobile" (keypress)="onlyNumberKey($event)"
                  appFormatPhoneNumber/>
              <!-- <label>Mobile</label> -->
              <!-- <a (click)="openModal(template)" *ngIf="mobile.valid && !is_mobile_verified">
                  <span class="form-control-feedback text-warning font-size-14 text-underline">Verify
                      <i class="ml-2 zmdi zmdi-alert-triangle"></i>
                  </span>
              </a> -->
              <!-- <span *ngIf="is_mobile_verified" class="form-control-feedback text-success font-size-14">Verified
                  <i class="ml-2 zmdi zmdi-check"></i>
              </span> -->
              <div *ngIf="f.mobile.errors" class="invalid-feedback custom-validation">
                  <p *ngIf="submitted && f.mobile.errors.required" class="help text-danger">
                      Please enter mobile number.
                  </p>
                  <p *ngIf="f.mobile.errors.minlength" class="help text-danger">
                      Mobile number contain minimum 10 digits.
                  </p>
                  <p *ngIf="f.mobile.errors.maxlength" class="help text-danger">
                      Mobile number contain maximum 10 digits.
                  </p>
              </div>
              <!-- <span class="form-control-feedback text-success font-size-14">Verified <i class="ml-2 zmdi zmdi-check"></i></span> -->
          </div>
          </div>
          <div class="form-label-group has-text">
            <input [type]="password_type" class="form-control form-control-lg" placeholder="Password" id="password-field" autocomplete="off"
              data-toggle="password" [formControl]="password" />
            <label>Password</label>
            <span class="form-control-feedback zmdi-hc-lg toggle-password" [ngClass]="is_show_password ? 'zmdi zmdi-eye' : 'zmdi zmdi-eye-off'"
              toggle="#password" (click)="onclisckShowPassword()"></span>
            <!-- <span class="form-control-feedback zmdi zmdi-eye-off zmdi-hc-lg toggle-password" toggle="#password-field"></span> -->
            <div *ngIf="f.password.errors" class="invalid-feedback custom-validation">
              <p *ngIf="submitted && f.password.errors.required" class="help text-danger">
                Please enter Password.
              </p>
              <p *ngIf="f.password.errors.minlength" class="help text-danger">
                Password must be 6 characters long.
              </p>
              <p *ngIf="f.password.errors.maxlength" class="help text-danger">
                Password contain maximum 20 Characters.
              </p>
            </div>
          </div>
          <div class="form-action text-right mt-4">
            <button class="btn btn-primary-theme btn-lg btn-block" type="submit">
              Sign Up
            </button>
            <!-- <input
              type="button"
              class="btn btn-primary-theme btn-lg btn-block"
              value="Sign Up"
              data-toggle="modal"
              data-target="#ThankYouAccountCreate"
            /> -->
          </div>
          <div class="text-center mt-4">
            <a routerLink="/auth/sign-in" class="text-primary text-uppercase font-size-18 font-weight-medium">Sign In</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- Thank You Account Created Modal -->
<ng-template #template>
  <app-message-box [title]="modalTitle" [message]="modalMessage" [buttonText]="modalButtonText" (buttonClicked)="onButtonClick()"></app-message-box>
</ng-template>