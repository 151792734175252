import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class TokenService {
  constructor() {}
  getToken(): string {
    return window.localStorage.alhaan;
  }

  saveToken(token: string) {
    window.localStorage.alhaan = token;
  }

  destroyToken() {
    localStorage.removeItem("alhaan");
    window.localStorage.removeItem("user");
  }
}
