import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { FormControl, UntypedFormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-add-to-load",
  templateUrl: "./add-to-load.component.html",
  styleUrls: ["./add-to-load.component.scss"],
})
export class AddToLoadComponent implements OnInit {
  submitted = false;
  dynamicValidationError;
  @Output() buttonClicked = new EventEmitter();
  @Output() fillQTY = new EventEmitter();
  @Input() truckId: number;
  @Input() orderQTY: number;
  @Input() capacity: number;

  fill_qty = new FormControl(null);
  form = new UntypedFormGroup({
    fill_qty: this.fill_qty,
  });

  constructor() {}

  ngOnInit() {
    if (this.capacity >= this.orderQTY) {
      this.dynamicValidationError = `Value could not be greater than remaining qty.`;
      this.fill_qty.setValidators([
        Validators.required,
        Validators.max(this.orderQTY),
        Validators.min(0.01),
        Validators.pattern(/^[0-9]/),
      ]);
    } else if (this.capacity < this.orderQTY) {
      this.dynamicValidationError = `Value could not be greater than truck capacity.`;
      this.fill_qty.setValidators([
        Validators.required,
        Validators.max(this.capacity),
        Validators.min(0.01),
        Validators.pattern(/^[0-9]/),
      ]);
    }
  }

  get f() {
    return this.form.controls;
  }

  onCancel() {
    this.fillQTY.emit({ truck_id: this.truckId, qty: 0 });
    // this.buttonClicked.emit();
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.valid) {
      this.fillQTY.emit({ truck_id: this.truckId, qty: this.fill_qty.value });
      // this.buttonClicked.emit();
    }
  }
}
