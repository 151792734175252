import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { SignInComponent } from "./sign-in/sign-in.component";
import { SignUpComponent } from "./sign-up/sign-up.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { CompleteProfileComponent } from "./complete-profile/complete-profile.component";
import { SubTemplateComponent } from "../scaffold/container/sub-template/sub-template.component";
import { LoginAuthGuard } from '../shared/guards/login_auth-guard';
import { AuthGuard } from '../shared/guards/auth-guard';


const routes: Routes = [
  {
    path: "",
    component: SubTemplateComponent,
    children: [
      { path: "sign-in", component: SignInComponent, canActivate: [LoginAuthGuard] },
      { path: "sign-up", component: SignUpComponent, canActivate: [LoginAuthGuard] },
      { path: "forgot-password", component: ForgotPasswordComponent, canActivate: [LoginAuthGuard] },
      { path: "reset-password", component: ResetPasswordComponent, canActivate: [LoginAuthGuard] },
      { path: "complete-profile", component: CompleteProfileComponent, canActivate: [AuthGuard] },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule { }
