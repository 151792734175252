import { Component, OnInit } from "@angular/core";
import { CoreService } from "../../../shared/services/core.service";
@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  constructor(private coreService: CoreService,) {}
  d = new Date();
  date =this.d.getFullYear();
  ngOnInit() {}
}
